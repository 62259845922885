<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="40" v-model="tag.name"
          :markFormDirty="false" />
      </b-col>
      <b-col sm="12">
        <InputText title="Cor" field="name" :required="false" :maxLength="20" :markFormDirty="false"
          v-model="tag.color" />
        <Color titulo="Cor" v-model="tag.color" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSaveTag" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveTag" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Color from "@nixweb/nixloc-ui/src/component/forms/Color";
import Tag from "@/components/modules/planner/tag/Tag.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TagCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText, Color },
  data() {
    return {
      formName: "tagCreateUpdate",
      tag: new Tag(),
      urlCreate: "/api/v1/planner/tag/create",
      urlUpdate: "/api/v1/planner/tag/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveTag() {
      if (this.tag.id) {
        let params = { url: this.urlUpdate, obj: this.tag };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSaveTag"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.tag };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);;
          }
          this.removeLoading(["btnSaveTag"]);
        });
      }
    },
    update(data) {
      this.tag = new Tag();
      this.tag.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "tagCreate") {
          this.resetValidation(this.formName);
          this.tag = new Tag();
        }
        if (event.name == "tagUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
