<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnTagCreate" title="Tag" classIcon="fas fa-plus-circle" type="primary" size="small"
          :clicked="create" />
      </div>
    </ViewTemplateConfiguration>
    <Modal title="Tag" :width="500" :height="250" v-show="showModal('tagCreateUpdate')">
      <TagCreateUpdate />
    </Modal>
  </div>
</template>

<script>

import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import TagCreateUpdate from '../../../components/modules/planner/tag/TagCreateUpdate.vue'

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TagView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    TagCreateUpdate
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/planner/tag/get-all",
        urlDeleteAllApi: "/api/v1/planner/tag/delete",
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            eventName: "tagUpdate",
          },
          {
            field: "colorHtml",
            type: "html",
            styleBody: "width: 200px;",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("tagCreateUpdate");
      this.addEvent({ name: "tagCreate" });
      this.removeLoading(["btnTagCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "tagUpdate")
          this.openModal("tagCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
